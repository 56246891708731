body{
  background-color: #fff;
}
.App-logo {
  animation: spin infinite 20s linear;
  height: 80px;
}

.App-header {
  /* background-color: #222;
  padding: 20px;
  color: white;
  text-align: center; */
}

.App-intro {
  font-size: large;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.radioCustomLabel {
  position: relative;
  width: 100% !important;
  margin: 0;
  padding: 0px;
  font-size: 16px;
  line-height: 1.5;
}
.answerOption {
	/*float: left;*/
	display: inherit;
	margin-right: 10px;
	padding-bottom: 6px;
}

.result-link {
  float: right;
    /* background-color: green; */
  color: #000000;
}
.status{
  color: green;
}
.list-grp {
  text-align: left;
    line-height: 1.5;
    padding: 10px;
    background: #ccc;
}
.quiz-story{
  padding: 30px;
  width: 100%;
  background-color: #ccc;
  /* padding-left: 10px;text-align: center; */
  background-color: #e8e5e5;
  margin: auto;
  min-height: 400px;
  
  border-radius: 10px;
}
.selected-btn {
  background-color: #54dc1c;
}
.bottom-footer{
  margin-top: 30px;
  /* background-color: #ccc; */
  text-align: right;
  height: auto;
  overflow: hidden;
}
.bottom-footer .Previous-btn{
  float: left;
}
.bottom-footer .next-btn{
  float: right;
}
.container{
  max-width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.subHeader .container{
  margin-top: 0px;
  margin-bottom: 0px;
}
.row{
  margin-left: -15px;
  margin-right: -15px;
}
.row .col-sm-6{
  padding-left: 15px;
  padding-right: 15px;
  width: 50%;
  float: left;
}
.row .col-sm{
  padding-left: 15px;
  padding-right: 15px;
  float: left;
}
.row .courseName{
	width: calc(100% - 200px);
	min-height: 10px;
}
.subHeader{
  border-top: 5px solid #ff6400;
  padding: 15px 0px 15px 0px;
  background: #3f3e3e;
  color: #fff;
  font-size: 14px;
  height: auto;
  overflow: hidden;
  margin-bottom: 50px;
}
.headerfront{
  height: auto;
  width: 100%;
  padding: 20px;
  overflow: hidden;
}
.timer{
  display: inline-block;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}
.timer span{
  font-size: 14px;
  color: #000;
  background: #fff;
  padding: 5px;
  width: 60px;
  height: 25px;
  margin-left: 10px;
  line-height: 20px;
  display: inline-block;
  text-align: left;
}
.text-right{
  text-align: right;
}
.answerOptions button{
  border:1px solid #3f3e3e;
  background: none;
  color: #3f3e3e !important;
  padding: 10px 15px;
}
.answerOptions button:hover, .answerOptions button.selected-btn{
    background: #3f3e3e;
    color: #fff !important;
}




a{text-decoration: none;}
a:hover{color:#aaa; }
.pull-right{float: right;}
.pull-left{float: left;}
.clear-fix{clear:both;}
div.logo{text-align: center; margin: 20px 20px 30px 20px; fill: #566375;}
#formWrapper{
 background: rgba(0,0,0,.2); 
 width:100%; 
 height:100%; 
 position: absolute; 
 top:0; 
 left:0;
 transition:all .3s ease;}
.darken-bg{background: rgba(0,0,0,.5) !important; transition:all .3s ease;}

div#form{
 position: absolute;
 width:360px;
 height:320px;
 height:auto;
 background-color: #fff;
 margin:auto;
 border-radius: 5px;
 padding:20px;
 left:50%;
 top:50%;
 margin-left:-180px;
 margin-top:-200px;
}
div.form-item{position: relative; display: block; margin-bottom: 20px;}
input{transition: all .2s ease;}
input.form-style{
 color:#8a8a8a;
 display: block;
 width: 90%;
 height: 44px;
 padding: 5px 5%;
 border:1px solid #ccc;
 -moz-border-radius: 27px;
 -webkit-border-radius: 27px;
 border-radius: 27px;
 -moz-background-clip: padding;
 -webkit-background-clip: padding-box;
 background-clip: padding-box;
 background-color: #fff;
 font-family:'HelveticaNeue','Arial', sans-serif;
 font-size: 105%;
 letter-spacing: .8px;
}


button{
  border: 0px none;
  cursor: pointer;
  position: relative;
  margin: 50px auto;
  border-radius: 0px;
  padding: 10px 40px;
  font-weight: 500;
  font-size: 18px;
  background: #ff6400;
  color: #fff !important;
  border-color: #ff6400;
  width: auto;
  margin: auto;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}
.headerTopMain{
  width:100%;
  height: auto;
  overflow: hidden;
}
.headerTopMain .logoLeft{
  float: left;
}
.headerTopMain .userNameRight{
  float: right;
}



@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.objective-answer {
    width: 100%;
    margin: 0px;
    padding: 10px;
    resize: none;
}
