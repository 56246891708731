/* Shared */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: #eee;
  font-family: sans-serif;
}

.container {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px;
}

button {
  width: 150px;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
}
@media (min-width: 769px) {
  .container {
    margin: 2.5rem auto;
  }
}

/* Result Component */
.result {
  padding: 1.5rem 2.5rem;
}

/* Quiz Component */
.answerOptions {
  margin: auto;
  min-height: 200px;
  padding: 15px 0px 0px 0px;
}

/* QuestionCount Component */
.questionCount {
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  background-color: #eeeeee;
}

/* Question Component */
.question {
  /* text-align: center; */
  font-size: 16px;
}
.question li{
  padding-bottom: 7px;
}
/* AnswerOption Component */
.answerOption input{
	margin-right: 7px;
}

.answerOption:hover {
  /* background-color: #eefbfe; */
}

.radioCustomButton {
  position: absolute;
  width: auto;
  opacity: 0;
}

.radioCustomButton,
.radioCustomLabel {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.radioCustomLabel {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 1.5rem 2.5rem 1.5rem 5rem;
  font-size: 16px;
  line-height: 1.5;
}

.radioCustomButton ~ .radioCustomLabel:before {
  position: absolute;
  top: 20px;
  left: 38px;
  width: 28px;
  height: 28px;
  content: '';
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 50%;
  transition: all 0.3s;
}

.radioCustomButton:checked ~ .radioCustomLabel:before {
  content: '';
  /* background: #8BC53F url(./svg/icon-check.svg) no-repeat; */
  background-size: 27px;
  border-color: #8BC53F;
}

/* Animation */
.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0.3s;
}

.fade-leave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
  ul#horizontal-list {
    min-width: 696px;
    list-style: none;
    padding-top: 20px;
  }
  ul#horizontal-list li {
    display: inline;
  }


#__react-alert__ div span{
	font-size: 14px;	
}
#__react-alert__ div button{
	padding: 10px;	
}
#__react-alert__ div div div{
	width: auto !important;
}